import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const JobPost = () => {
  return (
    <Container className="mt-5 job-post">
      <Row>
        <Col>
          <h1 className="text-center mb-5 mt-5">
            Fullstack Web Developer (Intern)
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>About Us:</h3>
          <p>
            Ecorvi, established in 2021, is a vibrant startup dedicated to
            digital transformation. We specialize in leveraging technology to
            craft digital-twin infrastructures tailored for various industries
            and academic sectors, driving efficiency and growth.
          </p>
          <p>
            Our proficiency ranges from innovative digital-twin solutions to
            custom software development. Rooted in forward-thinking, we are
            passionate about equipping businesses and educational institutions
            with top-notch digital solutions. Join Ecorvi in crafting a smarter
            digital future for businesses, from large corporations to small
            enterprises and Academic institutions. Together, let's harness
            technology's full potential with Ecorvi.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Position Overview:</h3>
          <p>
            We are looking for enthusiastic and motivated individuals for an
            unpaid internship at Ecorvi. As a Web Development Intern, you'll
            dive into the latest technologies, including Node.js, ReactJS, and
            Angular MEAN/MERN Stack. If you are passionate about coding and want
            to gain hands-on experience in web development, this position is
            tailored for you.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Key Responsibilities:</h3>
          <ul>
            <li>
              Collaborate with the development team to design and develop web
              applications using Node.js and React.
            </li>
            <li>
              Actively contribute to the maintenance and enhancement of existing
              web applications.
            </li>
            <li>Learn and apply best practices in software development.</li>

            <li>
              Contribute insights and ideas for the continuous evolution of our
              web development processes.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Requirements:</h3>
          <ul>
            <li>Good Knowledge of HTML,CSS,Bootstrap . </li>
            <li>Knowledge of JavaScript, Node.js, and React or equivalent.</li>
            <li>Basic understanding of RESTful API,GraphQL.</li>
            <li>
              Proficient understanding of code versioning tools, such as Git.
            </li>
            <li>Strong problem-solving skills and a passion for learning.</li>
            <li>Excellent communication and collaboration skills.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>Benefits:</h3>
          <ul>
            <li>
              Acquire hands-on skills and enrich your portfolio with real-world
              projects.
            </li>
            <li>Work closely with experienced professionals.</li>
            <li>
              Access guidance and support for your professional development.
            </li>
            <li>
              Receive a completion certificate upon successful conclusion.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3>How to Apply:</h3>
          <p>
            Interested candidates are invited to submit their resume, a brief
            cover letter, and any relevant portfolio or project links to
            <strong className="text-danger bg-info">
              {" "}
              careers@ecorvi.com{" "}
            </strong>
          </p>
        </Col>
      </Row>
      {/* <Row className="mt-4 mb-4">
        <Col className="text-center">
          <Button
            variant="primary"
            href="[Application Form Link]"
            target="_blank"
          >
            Apply Now
          </Button>
        </Col>
      </Row> */}
    </Container>
  );
};

export default JobPost;
