import React from "react";
import { Container, Row, Col, Carousel, Card } from "react-bootstrap";
import imagArray from "./Images";

const Products = () => {
  return (
    <>
      <Container fluid className="py-5 " id="products-content">
        <Row className="py-5 ">
          <Col  xl={6}>
            <div className="container col-11 ">
              <h5 className="card-title mt-4"> Digital Twin and IoT</h5>
              <hr></hr>
              <p className="card-text text-justify">
                Digital Twin is at the forefront of the Industry 4.0 revolution
                facilitated through advanced data analytics and the Internet of
                Things (IoT) connectivity. IoT has increased the volume of data
                usable from manufacturing, healthcare, and smart city
                environments. IoT is an advanced automation and analytics system
                which deals with artificial intelligence, sensor, networking,
                electronic, cloud messaging etc. to deliver complete systems for
                the product or services. The system created by IoT has greater
                transparency, control, and performance. IoT devices gathers and
                analyzes data from sensors and smart devices to enable efficient
                management of of available resources.
              </p>
              <ul>
                <li>Healthcare</li>
                <li>Smart Cities</li>
                <li>Retail</li>
                <li>Education</li>
                <li>Smart Environment</li>
              </ul>
            </div>
          </Col>
          <Col xl={6}>
            <Carousel id="dtslides">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagArray["smart-home.jpg"]}
                  alt="First slide"
                  height="500"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagArray["Transformation.jpg"]}
                  alt="Second slide"
                  height="500"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagArray["block-chain.jpg"]}
                  alt="Third slide"
                  height="500"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container id="products-content2">
        <Row className="mt-2">
          <Col lg={6} className="mb-3">
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                variant="top"
                src={imagArray["Environment.jpg"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>
                  <h4>Smart Environment</h4>{" "}
                </Card.Title>
                <Card.Text className="text-justify">
                  Smart Environment is a concept of the physical world that is
                  richly and invisibly interwoven with sensors, actuators,
                  displays, and computing devices, embedded seamlessly in the
                  everyday objects of our lives,connected through a continuous
                  network,collect and exchange data with other devices.
                  <br />
                  <br />
                  These IoT solutions Monitors or Measures the:
                  <ul>
                    <li>Temperature, pressure, light, sound intensity.</li>
                    <li>
                      Quality of the air by checking principal air pollutants.
                    </li>
                    <li>Pedestrian and vehicle traffic suggestion.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-3">
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                variant="top"
                src={imagArray["city.jpg"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>
                  <h4>Smart Cities</h4>{" "}
                </Card.Title>
                <Card.Text className="text-justify">
                  “Smartness is not a measure of how advanced or complex the
                  technology being adopted is, but how well a society uses
                  technology to solve its problems and address existential
                  challenges. (Singapore Smart Nation) ”. IoT solutions fall
                  under the umbrella of Smart Cities.
                  <br />
                  <br />
                  <ul>
                    <li>E-governance</li>
                    <li>Smart Waste Management</li>
                    <li>Smart Streetlighting</li>
                    <li>Smart Traffic Control</li>
                    <li>Safety and Security</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-3">
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                src={imagArray["Healthcare.png"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>
                  <h4>Health Care</h4>
                </Card.Title>
                <Card.Text className="text-justify">
                  IoT has applications in healthcare(IoMT) that benefit
                  patients, families, physicians, hospitals and insurance
                  companies etc. Medical IoT facilitates monitoring patient
                  health state and tracking medical staff and assets through the
                  network of smart devices and sensors connected to the cloud.
                  <br />
                  <br />
                  IoT devices can be used to enable remote health
                  monitoring(RPM) and emergency notification systems.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-3">
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                variant="top"
                src={imagArray["Retail.jpg"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>
                  <h4>Retail</h4>
                </Card.Title>
                <Card.Text className="text-justify">
                  Retailers are using IoT technology to enhance customer
                  experience, reduce costs, drive growth and improve overall
                  performance.
                  <br />
                  IoT solutions for Retail:
                  <br />
                  <ul>
                    <li>Food Safety Monitoring</li>
                    <li>Asset Tracking</li>
                    <li>Supply Chain and Logistics</li>
                    <li>Customer Satisfaction Monitoring</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>  
    </>
  );
};

export default Products;
