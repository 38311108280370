import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import imagArray from "./Images";

const Services = () => {
  return (
    <>
      <div className="intro-header">
        <div className="container-fluid ">
          <div className="intro-message d-flex justify-content-end align-items-center ">
            <h1>SOFTWARE DEVELOPMENT SERVICES</h1>
          </div>
        </div>
      </div>
      <Container>
        <h3 className="text-secondary mt-5">Services</h3>
        <hr />
        <Row className="justify-content-center mb-3 py-3" id="firstrow">
          <Col className="mb-3" lg={6}>
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                src={imagArray["Fullstack1.png"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>Full Stack Development</Card.Title>
                <Card.Text className="text-justify">
                  Software development across a full range of technologies, from
                  front-end prototyping to a complete set of back-end services.
                  <ul>
                    <li>Single Page Application</li>
                    <li>Responsive Web Applications</li>
                    <li>Customized Software Solutions</li>
                    <li>API Development</li>
                    <li>Application Maintenance</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-3" lg={6}>
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                src={imagArray["Responsive.png"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>Responsive-Design</Card.Title>
                <Card.Text className="text-justify">
                  Responsive design is a graphic user interface (GUI) design
                  approach used to create content that adjusts smoothly to
                  various screen sizes. We create user-friendly and responsive
                  Websites suitable for multiple Media devices such as:
                  <ul>
                    <li>Smartphones</li>
                    <li>Tablets</li>
                    <li>Desktops</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-3" lg={6}>
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                src={imagArray["Ecommerce.jpg"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>E-commerce Solutions</Card.Title>
                <Card.Text className="text-justify">
                  We provide e-commerce software development services for small
                  to medium range Enterprises.
                  <ul>
                    <li>Custom Web Portals</li>
                    <li>Shopping Carts</li>
                    <li>Mobile Applications</li>
                    <li>Payment Gateway</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-3" lg={6}>
            <Card>
              <Card.Img
                className="img-responsive w-75 mt-2"
                src={imagArray["BI.jpg"]}
                height="200"
              />
              <Card.Body>
                <Card.Title>BI Analytics & Data Science Services</Card.Title>
                <Card.Text className="text-justify">
                  We develop statistical, machine learning and AI models for our
                  customers to improve products, services and processes.
                  <ul>
                    <li>Business Intelligence Reports</li>
                    <li>Interactive Dashboards</li>
                    <li>AI-driven Analytics</li>
                    <li>Developing Machine Learning Models</li>
                    <li>Data Models & Visualization</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="key-technology">
        <h3 className="text-secondary mb-0">Key Technologies</h3>
        <hr />
        <Row className="mb-4" id="frontend">
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["html-logo.png"]}
              className="img-thumbnail"
              alt="HTML"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["css3-logo.png"]}
              className="img-thumbnail"
              alt="CSS"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["bootstrap-logo.png"]}
              className="img-thumbnail"
              alt="Bootstrap"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["react-logo.png"]}
              className="img-thumbnail"
              alt="React"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["javascript-logo.png"]}
              className="img-thumbnail"
              alt="JavaScript"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["Sass_Logo.png"]}
              className="img-thumbnail"
              alt="Sass"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["AngularJS.png"]}
              className="img-thumbnail"
              alt="AngularJS"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["jquery.png"]}
              className="img-thumbnail"
              alt="JQuery"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="mb-4" id="backend">
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["python-logo.png"]}
              className="img-thumbnail"
              alt="Python"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["java-logo.png"]}
              className="img-thumbnail"
              alt="Java"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["CPlus-logo.png"]}
              className="img-thumbnail"
              alt="C++"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["php-logo-1.png"]}
              className="img-thumbnail"
              alt="Php"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["django.png"]}
              className="img-thumbnail"
              alt="Django"
            />
          </Col>
          <Col xs={3} lg={2} md={2} className="mb-2">
            <img
              src={imagArray["nodejs-logo.png"]}
              className="img-thumbnail"
              alt="Node JS"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="mb-4" id="db">
          <Col xs={3} lg={2} sm={3} className="mb-2">
            <img
              src={imagArray["mongodb-logo.png"]}
              className="img-thumbnail"
              alt="MongoDB"
            />
          </Col>
          <Col xs={3} lg={2} sm={3} className="mb-2">
            <img
              src={imagArray["postgresql-logo.png"]}
              className="img-thumbnail"
              alt="Postgresql"
            />
          </Col>
          <Col xs={3} lg={2} sm={3} className="mb-2">
            <img
              src={imagArray["mysql-logo-1.png"]}
              className="img-thumbnail"
              alt="MySQL"
            />
          </Col>
          <Col xs={3} lg={2} sm={3} className="mb-2">
            <img
              src={imagArray["oracle-logo.png"]}
              className="img-thumbnail"
              alt="Oracle"
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>      
    </>
  );
};

export default Services;
