import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Ecorvi_Logo from "../images/Ecorvi_Logo.png";
const Footer = () => {
  return (
    <>
      <Container fluid variant="dark" id="mainfooter">
        <Container className="py-3">
          <Row className="mb-4">
            <Col xs={12} md={4}>
              <img src={Ecorvi_Logo} width="120px" className="logo" alt="" />
              <h5>Ecorvi Pvt. Ltd.</h5>
            </Col>
            <Col xs={12} lg={4} className="mt-3 mb-0">
              <ul>
                <li>Industrial Digital Transformation</li>
                <li>Research &amp; Development</li>
                <li>Smart Home & IoT Solutions</li>
              </ul>
            </Col>
            <Col xs={12} lg={4} className="mt-3">
              <ul>
                <li>Fullstack & Web Development</li>
                <li>BI & Data Sciences</li>
              </ul>
            </Col>
          </Row>   
        
        </Container>
      </Container>
      <div className="container-fluid mx-auto fixed-bottom " id="bottomnav">
            Copyright © 2021–2024 &nbsp; &nbsp; www.ecorvi.com
      </div>
    </>
  );
};

export default Footer;
