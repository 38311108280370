import React from 'react';
const StickyContacts = () => {
    return ( <>
     <div className="floating-icons1">
        <a
          href="http:/wa.me/919553266529"
          target="_blank"
          rel="noreferrer"
          className="icon-whatsapp"
        >
          <i className="fab fa-whatsapp"></i>
          <span className="text-success">Chat with us</span>
        </a>
      </div>
      <div className="floating-icons2">
        <a
          href="https://www.linkedin.com/company/ecorvi-pvt-ltd/"
          target="_blank"
          rel="noreferrer"
          className="icon-whatsapp"
        >
          <i className="fab fa-linkedin"></i>
          <span>LinkedIn</span>
        </a>
      </div>
      <div className="floating-icons3">
        <a href="mailto:info@ecorvi.com"  className="icon-email">
          <i className="text-danger fas fa-envelope"></i>
          <span className="text-danger">info@ecorvi.com</span>
        </a>
      </div>
    </>
        
  );
}
 
export default StickyContacts;