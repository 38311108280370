import React from "react";

import JobPost from "./JobPost";
const Career = () => {
  return (
    <>
     <JobPost />   
    </>
      
  );
};

export default Career;
